<template>
	<header class="header">
		<div
			class="header_wrapper"
			:class="filterActive"
			:style="headerStyle"
		>
			<top-menu-new :fetched-data="fetchedData" />
			<client-only v-if="videosSource && safariAgent">
				<picture id="video" class="embed-responsive embed-responsive-16by9">
					<source class="embed-responsive-item" :srcset="`${fetchedData.video_mp4}`" type="video/mp4">
					<img :src="`${fetchedData.top_cover_image_url_default}`">
				</picture>
			</client-only>

			<client-only v-if="videosSource && !safariAgent">
				<video
					id="video"
					playsinline
					muted
					autoplay
					preload="auto"
					loop
					class="embed-responsive embed-responsive-16by9"
					:poster="`${fetchedData.top_cover_image_url_default}`"
				>
					<source :src="`${fetchedData.video_mp4}`" class="embed-responsive-item" type="video/mp4">
				</video>
			</client-only>
			<div class="container">
				<div class="header_main">
					<!-- <img src="/donation/header_frame.svg" class="frame" /> -->

					<div class="row">
						<div class="col-12 col-md-12 col-lg-8">
							<div class="title_block">
								<div v-if="fetchedData.tagline" class="tagline">
									{{ fetchedData.tagline }}
								</div>
								<h1>{{ fetchedData.title }}</h1>
								<div class="title">
									<div v-if="fetchedData.subtitle">
										{{ fetchedData.subtitle }}
									</div>
									<div
										v-if="fetchedData.description"
										class="subtitle"
										v-html="fetchedData.description"
									/>
								</div>
							</div>
						</div>

						<div v-if="isImageVisible" class="col-12 col-md-12 col-lg-4">
							<div class="slider-side__item h-100 d-flex align-items-center border border-white">
								<div class="item-body p-4">
									<div class="title mb-3">
											Узнайте больше о корпоративном обучении и попробуйте собрать программу самостоятельно
									</div>
									<a href="/constructor" class="btn btn-primary">
										Подробнее
									</a>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div
			v-if="isMenuVisible"
			class="event_menu"
		>
			<div class="container">
				<div class="swiper_menu">
					<div v-swiper:menuSwiper="options">
						<div class="swiper-wrapper">
							<div v-for="(block, index) in fetchedData.blocks" :key="index" class="swiper-slide">
								<a
									v-if="block.type ==='panel-navigation'"
									v-scroll-to="'#' + block.type + block.id"
									href="#"
								>{{ block.content.block_title.ru }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			v-else-if="isMenuCustom"
			class="event_menu"
		>
			<div class="container">
				<div class="swiper_menu">
					<div v-swiper:menuSwiper="options">
						<div class="swiper-wrapper">
							<div class="swiper-slide">
								<a v-scroll-to="'#about'" href="#">О проекте</a>
							</div>
							<div class="swiper-slide">
								<a v-scroll-to="'#rules'" href="#">Условия участия</a>
							</div>
							<div class="swiper-slide">
								<a href="/probono/programmy-skolkovo-pro-bono/">Программы</a>
							</div>
							<div class="swiper-slide">
								<a v-scroll-to="'#partners'" href="#">Партнеры</a>
							</div>
							<div class="swiper-slide">
								<a v-scroll-to="'#media'" href="#">Медиа</a>
							</div>
							<div class="swiper-slide">
								<a v-scroll-to="'#apply'" href="#">Подать заявку</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<new-line-menu v-else-if="dynamic" :links="links" />
		<line-menu v-else :links="links" />
	</header>
</template>

<script>
import { directive as swiper } from 'vue-awesome-swiper';
import { HEADER_IMAGE_POSITIONS, BOOTSTRAP_BREAKPOINTS } from '@/utils/dictionaries';

import TopMenuNew from '@/components/layouts/_partials/TopMenuNew.vue';
import LineMenu from '@/components/layouts/_partials/LineMenu.vue';
import NewLineMenu from '@/components/layouts/_partials/NewLineMenu.vue';
import checkScreenWidth from '@/mixins/checkScreenWidth';

const firstIdArr = [2632, 2672, 2682, 2692, 2702, 2682, 2702, 1242, 1272, 1262, 282, 622, 612, 552, 542, 322, 312, 302, 292];
const secondIdArr = [672, 1172, 1552, 2632, 2916, 3246, 3826, 3846, 4125, 4145, 4185, 2896];
const customMenu = [3955];

export default {
	name: 'CenterHeader',
	components: {
		TopMenuNew,
		LineMenu,
		NewLineMenu,
	},
	directives: {
		swiper,
	},
	mixins: [checkScreenWidth],
	props: {
		fetchedData: { type: Object, required: true },
		links: { type: Array, required: true },
		dynamic: { type: Boolean, default: false },
	},
	data() {
		return {
			BOOTSTRAP_BREAKPOINTS,
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				cssMode: true,
				loop: false,
			},
			isImageVisible: false,
			isMenuVisible: false,
			isMenuCustom: false,
			headerImagePosition: '',
			headerStyle: null,
			videosSource: '',
			poster: '',
			color: '',
			filterActive: '',
			safariAgent: null,
		};
	},
	watch: {
		screenWidth() {
			this.loadStyles();
		},
	},
	created() {
		this.headerImagePosition = HEADER_IMAGE_POSITIONS[this.fetchedData.mobile_image_center_position];
	},
	mounted() {
		if (process.client) {
			this.browserDetect();
			this.initVideo();
		}
		if (document.readyState === 'complete') {
			this.loadStyles();
		} else {
			window.addEventListener('load', this.loadStyles, {
				once: true,
				passive: true,
			});
		}
		this.isImageVisible = firstIdArr.includes(this.fetchedData.id);
		this.isMenuVisible = secondIdArr.includes(this.fetchedData.id);
		this.isMenuCustom = customMenu.includes(this.fetchedData.id);
	},
	methods: {
		browserDetect() {
			if (navigator.vendor && navigator.userAgent) {
				this.safariAgent = navigator.vendor.includes('Apple')
					&& !navigator.userAgent.includes('CriOS')
					&& !navigator.userAgent.includes('FxiOS');
			}
		},
		initVideo() {
			if (this.fetchedData.video_mp4) {
				this.videosSource = `${this.fetchedData.video_mp4}`;
			}
		},
		loadStyles() {
			if (this.fetchedData.video_mp4) {
				this.filterActive = "filter";
				if (this.fetchedData.color) {
					this.headerStyle = `--new-color: ${this.fetchedData.color}`;
				}
			} else {
				if (!this.fetchedData.mobile_image.startsWith('https')) {
					this.headerStyle = `background-image:url(${this.fetchedData.top_cover_image_url_default})`;
				} else if (this.screenWidth > this.BOOTSTRAP_BREAKPOINTS.md) {
					this.headerWrapperStyle();
				} else {
					this.headerStyle = `background-image:url(${this.fetchedData.mobile_image}); background-position: ${this.headerImagePosition}`;
				}
			}
		},
		headerWrapperStyle() {
			const image = this.fetchedData.top_cover_image_default;
			if (image && image.src) {
				this.headerStyle = `background-image:url(${image.src})`;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/event-menu";
	@import "~/assets/styles/components/home/center_research-header";
</style>

<style lang="scss">
	@import "assets/styles/sk_variables";
	// For use bootstrap vars and mixins inside
	@import "assets/styles/bootstrap/scss/functions";
	@import "assets/styles/bootstrap/scss/variables";
	@import "assets/styles/bootstrap/scss/mixins";
	.header {
		.header_main {
			z-index: 1;
		}
		.header_block {
			z-index: 2;
		}
		.filter {
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				aspect-ratio: 1 / 1;
				background-color: var(--new-color, $primary) !important;
				mix-blend-mode: multiply;
				z-index: 1;
			}
		}
		#video {
			z-index: 0;
			position: absolute !important;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			overflow: hidden;
			display: flex;
			object-fit: cover;
			align-items: center;
			justify-content: center;
			width: 100% !important;
			height: 100% !important;
			filter: grayscale(1);

			img {
				max-width: unset;
			}
		}
	}
</style>
