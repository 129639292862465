<template>
	<div class="event_menu">
		<div class="container">
			<div class="swiper_menu">
				<div v-swiper:menuSwiper="options">
					<div class="swiper-wrapper">
						<div v-for="(link, index) in links" :key="index" class="swiper-slide">
							<nuxt-link
								:to="link.to"
								:class="{'home icon-home': index === 0}"
							>
								{{ link.name }}
							</nuxt-link>
						</div>
					</div>
					<div class="event-button-prev icon-pin_left" />
					<div class="event-button-next icon-pin_right" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { directive as swiper } from 'vue-awesome-swiper';

export default {
	name: 'NewLineMenu',
	directives: {
		swiper,
	},
	props: {
		links: { type: Array, required: true },
		currentPath: { type: String, default: null },
	},
	data() {
		return {
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				cssMode: true,
				loop: false,

				navigation: {
					nextEl: '.event-button-next',
					prevEl: '.event-button-prev',
				},
			},
		};
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/event-menu";
</style>
